import request from '@/utils/request'
import {guid} from "@/utils/ruoyi";


// 查询商品列表
export function listGoods(query) {
  return request({
    url: '/goods/goods/list',
    method: 'get',
    params: query
  })
}
// 导入待发货
export function findZTGoodsList (data) {
  return request({
    url: '/goods/goods/findZTGoodsList',
    method: 'post',
    data: data
  })
}

// 查询商品分页
export function pageGoods(data) {
  data.date=guid()
  return request({
    url: '/goods/goods/page',
    method: 'post',
    data: data
  })
}

// 查询商品池列表
export function commodityPool(data) {
  return request({
    url: '/goods/goods/commodityPool',
    method: 'post',
    data: data
  })
}

export function updateSort(data) {
  return request({
    url: '/goods/goods/sort',
    method: 'post',
    data: data
  })
}
// 查询商品分页
export function activePage(data) {
  data.date=guid()
  return request({
    url: '/goods/goods/activePage',
    method: 'post',
    data: data
  })
}
// 查询商品详细
export function getGoods(data) {
  return request({
    url: '/goods/goods/detail',
    method: 'get',
    params: data
  })
}

// 新增商品
export function addGoods(data) {
  return request({
    url: '/goods/goods/add',
    method: 'post',
    data: data
  })
}

// 修改商品
export function updateGoods(data) {
  return request({
    url: '/goods/goods/edit',
    method: 'post',
    data: data
  })
}

// 删除商品
export function delGoods(data) {
  return request({
    url: '/goods/goods/delete',
    method: 'post',
    data: data
  })
}
export function restore(data) {
  return request({
    url: '/goods/goods/restore',
    method: 'post',
    data: data
  })
}

export function batchOn(data) {
  return request({
    url: '/goods/goods/batchOn',
    method: 'post',
    data: data
  })
}


export function batchOff(data) {
  return request({
    url: '/goods/goods/batchOff',
    method: 'post',
    data: data
  })
}
// 导入待发货
export function importData (data) {
  return request({
    url: '/goods/goods/import',
    method: 'post',
    data: data
  })
}
export function batchCopy(data) {
  return request({
    url: '/goods/goods/copy',
    method: 'post',
    data: data
  })
}

export function batchOnGoodType(data) {
  return request({
    url: '/goods/goods/batchOnGoodType',
    method: 'post',
    data: data
  })
}


export function batchOffGoodType(data) {
  return request({
    url: '/goods/goods/batchOffGoodType',
    method: 'post',
    data: data
  })
}
export function recommendOnChange(data) {
  return request({
    url: '/goods/goods/recommendOnChange',
    method: 'post',
    data: data
  })
}


export function recommendOffChange(data) {
  return request({
    url: '/goods/goods/recommendOffChange',
    method: 'post',
    data: data
  })
}
export function newOnChange(data) {
  return request({
    url: '/goods/goods/newOnChange',
    method: 'post',
    data: data
  })
}


export function newOffChange(data) {
  return request({
    url: '/goods/goods/newOffChange',
    method: 'post',
    data: data
  })
}